<template>
  <div>
    <v-container>
      <!--      <h1 style="text-align: left;margin-bottom: 20px">{{$t('home')}}</h1>-->
      <v-row>
        <div style="padding: 30px;text-align: justify;" class="animate__animated animate__delay-1s  animate__slideInUp"
             v-html="getTranslate(intro)"></div>

      </v-row>
    </v-container>
    <!--        color="#228B22"-->

    <v-card
        color="#b8860b"
        style="border-top-right-radius: 70%"
        dark
    >
      <v-container>

        <v-row class="animate__animated animate__delay-3s animate__fadeInUp">
          <v-col cols="12" md="7" sm="7">
            <div style="text-align: justify;color: white;font-size: 20px;padding: 20px">
              <span v-html="getTranslate(messageFromChairman || '')"></span>
            </div>
          </v-col>
          <v-col cols="12" md="5" sm="5" style="padding: 0px">
            <v-img
                :src="url"></v-img>
          </v-col>
        </v-row>


      </v-container>
    </v-card>
    <observer @on-change="onChange">
      <div v-if="visible" class="animate__animated animate__slower animate__slideInUp">
      <v-container style="text-align: left">
        <h1>{{ $t('preface') }}</h1>
        <h4 v-html="getTranslate(preface1)"></h4>
        <image-list :img-list="preface1ImgList"></image-list>
        <br>

        <h4 v-html="getTranslate(preface2)"></h4>
        <image-list :img-list="preface2ImgList"></image-list>
        <br>

        <h4 v-html="getTranslate(preface3)"></h4>
        <image-list :img-list="preface3ImgList"></image-list>
        <br>
        <h4 v-html="getTranslate(preface4)"></h4>
        <image-list :img-list="preface4ImgList"></image-list>

      </v-container>
      </div>
    </observer>
    <!--    <Carousel2/>-->
    <br><br>
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.491717106646!2d103.24812771482306!3d13.067992990792733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310549246e823a2d%3A0x5a7b810c4dca3788!2sPhou%20Poy%20Rice%20Mill!5e0!3m2!1sen!2skh!4v1633769508161!5m2!1sen!2skh"
        width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy"></iframe>

  </div>
</template>

<script>

//import Carousel2 from "@/views/layout/carousel2";
import ImageList from "@/components/imagesList";
import VCharts from 'v-charts'
import Vue from "vue";
import {Constants} from "@/libs/constant";
import axios from "axios";

Vue.use(VCharts);
import Observer from 'vue-intersection-observer'

export default {
  name: 'Home',
  components: {ImageList, Observer},
  data() {
    this.chartTeacherSetting = {
      labelMap: {
        _id: this.$t("type"),
        number: this.$t("number"),
      },
      label: {
        normal: {show: true, position: "top"}
      },

    }
    return {
      videoLink: "",
      videoEmbedCode: "",
      windowSize: {
        x: 0,
        y: 0
      },
      visible: false,
      newsList: [],
      chartTeacher: {
        columns: ["_id", "number"],
        rows: [
          {"_id": "Male", "number": 55},
          {"_id": "Female", "number": 11},

        ]
      },
      chartStudent: {
        columns: ["_id", "number"],
        rows: [
          {"_id": "Male", "number": 500},
          {"_id": "Female", "number": 490},

        ]
      },
      url: "",
      intro: {
        en: "",
        km: "",
        cn: "",
      },
      messageFromChairman: {
        en: "",
        km: "",
        cn: "",
      },
      preface1: {
        en: "",
        km: "",
        cn: "",
      },
      preface2: {
        en: "",
        km: "",
        cn: "",
      },
      preface3: {
        en: "",
        km: "",
        cn: "",
      },
      preface4: {
        en: "",
        km: "",
        cn: "",
      },
      preface1ImgList: [],
      preface2ImgList: [],
      preface3ImgList: [],
      preface4ImgList: []
    }
  },
  mounted() {
    this.onResize()
  },
  watch: {
    "videoLink"(val) {
      let vm = this;
      vm.videoEmbedCode = "";
      if (val && val.split('youtu.be').length === 2) {
        vm.videoEmbedCode = val.replace("youtu.be", "youtube.com/embed");
      } else if (val && val.split('/watch?v=').length > 0) {
        vm.videoEmbedCode = "https://www.youtube.com/embed/" + val.split('/watch?v=')[1].split("&list=")[0].split('&t=')[0];
      }
    },
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    onChange(entry) {
      // After loading Cancel monitoring, optimise performance
      this.visible = entry.isIntersecting
    },
    fetchHome() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getHome;
      return new Promise((resolve, reject) => {
        axios.post(url, {branchId: Constants.branchId}, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.intro = res.data.data.intro;
                vm.url = res.data.data.url || "";
                vm.messageFromChairman = res.data.data.messageFromChairman;
                vm.preface1 = res.data.data.preface1;
                vm.preface1ImgList = res.data.data.preface1ImgList || [];

                vm.preface2 = res.data.data.preface2;
                vm.preface2ImgList = res.data.data.preface2ImgList || [];

                vm.preface3 = res.data.data.preface3;
                vm.preface3ImgList = res.data.data.preface3ImgList || [];

                vm.preface4 = res.data.data.preface4;
                vm.preface4ImgList = res.data.data.preface4ImgList || [];

              }
            },
            error => {
              reject(error);

            }
        );
      });
    }
  },
  created() {
    let vm = this;
    vm.newsList = vm.getNews().slice(0, 5);
    vm.videoLink = "https://www.youtube.com/watch?v=hDB-KjY7l-4";
    this.fetchHome();
  }
}
</script>


<style scoped>
.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 700px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 700px;
}

.gmap_iframe {
  height: 700px !important;
}

body {
  font-family: "Khmer OS Battambang";
}
</style>

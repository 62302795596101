<template>
  <div>
    <v-container>
      <h1 style="text-align: left;margin-bottom: 20px">{{ $t('newsAndEvents') }}</h1>
      <div v-if="!$vuetify.breakpoint.mobile"  class="animate__animated animate__delay-1s  animate__slideInUp">
        <div class="ten_news_item" v-for="(d,i) in newsList" :key="i" style="cursor: pointer" @click="gotoDetail(d)">
          <div style="text-align: left">
            <span class="ten_tagline">{{ d.createdAt | formatDate }}</span>
            <h1 v-html="cutText(getTranslate(d.title), 50)"></h1>
            <p v-html="cutText(getTranslate(d.body), 500)"></p>
          </div>
          <div>
            <v-img :src="d.url" style="width: 480px;height: 320px" contain></v-img>
          </div>
        </div>
      </div>
      <div v-if="$vuetify.breakpoint.mobile"  class="animate__animated animate__delay-1s  animate__slideInUp">
        <div class="bBottom" v-for="(d,i) in newsList" :key="i" style="cursor: pointer" @click="gotoDetail(d)">
          <div style="text-align: left">
            <span class="ten_tagline">{{ d.createdAt | formatDate }}</span>
            <h1 v-html="cutText(getTranslate(d.title), 50)"></h1>
            <p v-html="cutText(getTranslate(d.body), 500)"></p>
          </div>
          <div>
            <v-img :src="d.url" style="width: 480px;height: 320px" contain></v-img>
          </div>
        </div>
      </div>

    </v-container>
  </div>
</template>

<script>
import MyMixin from "@/mixins/mixin"
import {Constants} from "@/libs/constant";
import axios from "axios";

export default {
  name: "NewsAndEvent",
  mixins: [MyMixin],
  data() {
    return {
      newsList: []
    }
  },
  methods: {
    cutText(d, length) {
      if (!d) return "";
      return d.substr(0, length);
    },
    gotoDetail(doc) {
      this.$router.push({name: 'NewsEventsDetail', params: {doc: doc}});
    },
    fetchNewsAndEvent() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getNewsAndEvent;
      return new Promise((resolve, reject) => {
        axios.post(url, {branchId: Constants.branchId}, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.newsList = res.data.data;
              }
            },
            error => {
              reject(error);

            }
        );
      });
    }
  },
  created() {
    let vm = this;
    //vm.newsList = vm.getNews().slice(0, 100);
    vm.fetchNewsAndEvent();
  }
}
</script>


<style scoped>
.ten_news_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px 0px;
  padding: 10px 0;
  border-bottom: 1px solid #f4f4f4;
}
</style>
<template>
  <div>
    <v-container>
      <h1 style="text-align: left;margin-bottom: 20px">{{ $t('productionLine') }}</h1>
      <div v-for="(d,i) in dataList" :key="i" style="cursor: pointer">
        <div style="text-align: left">
          <p v-html="getTranslate(d.body)"  class="animate__animated animate__delay-1s  animate__slideInUp"></p>
        </div>
        <br>
        <image-list :img-list="d.url"></image-list>
      </div>
    </v-container>
  </div>
</template>

<script>
import MyMixin from "@/mixins/mixin"
import {Constants} from "@/libs/constant";
import axios from "axios";
import ImageList from "@/components/imagesList";

export default {
  name: "ProductionLine",
  mixins: [MyMixin],
  data() {
    return {
      dataList: []
    }
  },
  components: {ImageList},

  methods: {
    cutText(d, length) {
      if (!d) return "";
      return d.substr(0, length);
    },
    fetchProductionLine() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getProductionLine;
      return new Promise((resolve, reject) => {
        axios.post(url, {branchId: Constants.branchId}, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.dataList = res.data.data;
              }
            },
            error => {
              reject(error);

            }
        );
      });
    }
  },
  created() {
    let vm = this;
    //vm.dataList = vm.getNews().slice(0, 100);
    vm.fetchProductionLine();
  }
}
</script>


<style scoped>

</style>
<template>
  <v-row>
    <CoolLightBox
        :items="imgList"
        :index="index"
        @close="index = null">
    </CoolLightBox>

    <v-slide-group
        v-model="model"
        class="pa-4"
        center-active
        show-arrows
    >
      <v-slide-item
          v-for="(n,i) in imgList"
          :key="n"
          v-slot="{ active, toggle }"
      >
        <v-card
            :color="active ? 'primary' : 'grey lighten-1'"
            class="ma-4"
            height="200"
            width="250"
            elevation="2"
            @click="index = i,toggle"
        >

            <v-img
                :src="n"
                :lazy-src="n"
                aspect-ratio="1"
                class="grey lighten-2"
            >
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>

        </v-card>
      </v-slide-item>
    </v-slide-group>
<!--    <v-col
        v-for="(n,i) in imgList"
        :key="i"
        class="d-flex child-flex"
        cols="12"
        @click="index = i"
        md="3"
        sm="3"
    >
      <v-img
          :src="n"
          :lazy-src="n"
          aspect-ratio="1"
          class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>-->
  </v-row>
</template>
<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
export default {
  name: "ImageList",
  props: ['imgList'],
  components: {
    CoolLightBox
  },
  data() {
    return {
      model:"",
      index: null

    }
  }
}
</script>
<template>
  <div>
    <v-container v-if="doc">

      <v-col cols="12" sm="12" md="12" style="text-align: left">
        <span class="ten_tagline">{{ (doc && doc.createdAt || "") | formatDate }}</span>
        <b>
          <h1><p style="font-family: 'Khmer OS Muol Light'" v-html="getTranslate(doc.title)">
          </p></h1>
        </b>

        <p v-html="getTranslate(doc.body)"  class="animate__animated animate__delay-1s  animate__slideInUp">
        </p>
        <v-img v-if="doc.url" :src="doc.url"></v-img>
        <div v-if="doc.urlList && doc.urlList.length>0">
          <CoolLightBox
              :items="doc.urlList"
              :index="index"
              @close="index = null">
          </CoolLightBox>
          <v-carousel :show-arrows="false" cycle
                      style="max-height: 600px !important;">
            <v-carousel-item
                v-for="(img,i) in  doc.urlList"
                :key="i"
                :src="img"
                @click="index = i"
                lazy-src="../assets/loader.gif"
            >
            </v-carousel-item>

          </v-carousel>
        </div>
      </v-col>

      <div v-if="videoEmbedCode">
        <h1 style="text-align: left;margin-top: 50px">{{$t('video')}}</h1>
        <iframe v-show="!!videoEmbedCode" width="100%"
                :height="$vuetify.breakpoint.mobile ? (windowSize.x/2) : (windowSize.x/2)-(windowSize.x/2)*0.4"
                :src="videoEmbedCode" frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </div>
    </v-container>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: "NewsAndEventsDetail",
  components: {
    CoolLightBox
  },
  data() {
    return {
      doc: {},
      videoLink: "",
      videoEmbedCode: "",
      windowSize: {
        x: 0,
        y: 0
      },
      index: null
    }
  },
  created() {
    this.doc = this.$route.params.doc;
    this.videoLink = this.doc && this.doc.videoUrl || "";

  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    }
  },
  mounted() {
    this.onResize()
  },
  watch: {
    "videoLink"(val) {
      let vm = this;
      vm.videoEmbedCode = "";
      if (val && val.split('youtu.be').length === 2) {
        vm.videoEmbedCode = val.replace("youtu.be", "youtube.com/embed");
      } else if (val && val.split('/watch?v=').length > 0) {
        vm.videoEmbedCode = "https://www.youtube.com/embed/" + val.split('/watch?v=')[1].split("&list=")[0].split('&t=')[0];
      }
    },
  },
}
</script>
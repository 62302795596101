<template>
  <div>
    <v-container>
      <h1 style="text-align: left;margin-bottom: 20px">{{ $t('ourClient') }}</h1>
      <div>
        <v-row>
          <v-col cols="12" md="12" sm="12" v-for="(d,i) in dataList" :key="i" style="cursor: pointer;text-align: center"
                 @click="gotoDetail(d)">
            <img v-if="d.url" :src="d.url" style="width: 100%">
            <span v-if="!d.url" class="white--text text-h5" v-html="d.title.en.substr(3,1)"></span>
            <h3 v-html="getTranslate(d.title)"></h3>
            <p v-html="getTranslate(d.body)"></p>
            <v-divider inset/>
          </v-col>
        </v-row>
      </div>

    </v-container>
  </div>
</template>

<script>
import MyMixin from "@/mixins/mixin"
import {Constants} from "@/libs/constant";
import axios from "axios";

export default {
  name: "OurClient",
  mixins: [MyMixin],
  data() {
    return {
      dataList: []
    }
  },
  methods: {
    cutText(d, length) {
      if (!d) return "";
      return d.substr(0, length);
    },
    gotoDetail(doc) {
      this.$router.push({name: 'NewsEventsDetail', params: {doc: doc}});
    },
    fetchProduct() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getClient;
      return new Promise((resolve, reject) => {
        axios.post(url, {branchId: Constants.branchId}, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.dataList = res.data.data;
              }
            },
            error => {
              reject(error);

            }
        );
      });
    }
  },
  created() {
    let vm = this;
    vm.fetchProduct();
  }
}
</script>


<style scoped>
.ten_news_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px 0px;
  padding: 10px 0;
  border-bottom: 1px solid #f4f4f4;
}
</style>
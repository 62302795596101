<template>
  <div class="about" style="text-align: left">
    <v-container>
      <h1 style="text-align: left;margin-bottom: 20px">{{ $t('organizationChart') }}</h1>
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <img class="text-bg"
               style="width: 100%; margin-top: 40px;margin-bottom: 40px"
               :src="url"
               alt="goal">

        </v-col>
      </v-row>
      <div v-html="getTranslate(body)"  class="animate__animated animate__delay-1s  animate__slideInUp"></div>

      <h1 class="title" style="padding-top: 15px;font-family: Roboto"><p>{{ $t('mapOfInstitute') }}</p></h1>

    </v-container>
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.491717106646!2d103.24812771482306!3d13.067992990792733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310549246e823a2d%3A0x5a7b810c4dca3788!2sPhou%20Poy%20Rice%20Mill!5e0!3m2!1sen!2skh!4v1633769508161!5m2!1sen!2skh"
        width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy"></iframe>

  </div>
</template>

<script>
import {Constants} from "@/libs/constant";
import axios from "axios";

export default {
  data() {
    return {
      body:{
        en:"",
        km:"",
        cn:"",
      },
      url:""
    }
  },
  methods:{
    fetchOrganization() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getOrganization;
      return new Promise((resolve, reject) => {
        axios.post(url, {branchId: Constants.branchId}, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.body = res.data.data.body;
                vm.url = res.data.data.url;

              }
            },
            error => {
              reject(error);

            }
        );
      });
    }
  },
  created() {
    this.fetchOrganization();
  }
}
</script>

<style scoped>
.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 700px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 700px;
}

.gmap_iframe {
  height: 700px !important;
}

.mission {
  background: #FBFBFB;
  height: 600px;
  width: 100%;
  position: relative;
  margin-top: 96px;
  padding: 60px 96px;
  box-sizing: border-box;
  margin-bottom: 50px;
}


.mission .line {
  width: 1px;
  height: 189px;
  background: #D8D9DC;
  position: absolute;
  left: 55.24%;
  top: 67px;
  display: none;
}

.mission .r-mess {
  float: right;
  width: 405px;
  height: 190px;
  position: relative;
}

body {
  color: #fff;
  line-height: 1.5;
  font-size: 14px;
  font-family: 'PingFangSC-Regular', 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
  -webkit-font-smoothing: antialiased;
}

.mission .l-mess .label {
  font-size: 30px;
  color: #2A2E2E;
  letter-spacing: 0;
  font-family: 'TencentSansw7';
}

.mission .l-mess {
  float: left;
}

.mission .r-mess .label {
  font-size: 20px;
  color: #2A2E2E;
  letter-spacing: 0;
  line-height: 36px;
}

.mission .r-mess .desc {
  font-size: 14px;
  color: #5F6464;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: 16px;
}

.mission .r-mess:before {
  content: ' ';
  width: 1px;
  position: absolute;
  left: -60px;
  top: 0;
  display: block;
  bottom: 0;
  background: #D8D9DC;
}

.mission .text-bg {
  width: 824px;
  position: absolute;
  left: 82px;
  bottom: 0;
}


/*Goal*/
.goal {
  background: #FBFBFB;
  height: 300px;
  width: 100%;
  position: relative;
  margin-top: 96px;
  padding: 60px 96px;
  box-sizing: border-box;
  margin-bottom: 50px;
}


.goal .line {
  width: 1px;
  height: 160px;
  background: #D8D9DC;
  position: absolute;
  left: 55.24%;
  top: 67px;
  display: none;
}

.goal .r-mess {
  float: right;
  width: 405px;
  height: 190px;
  position: relative;
}

.goal .l-mess .label {
  font-size: 30px;
  color: #2A2E2E;
  letter-spacing: 0;
  font-family: 'TencentSansw7';
}

.goal .l-mess {
  float: left;
}

.goal .r-mess .label {
  font-size: 20px;
  color: #2A2E2E;
  letter-spacing: 0;
  line-height: 36px;
}

.goal .r-mess .desc {
  font-size: 14px;
  color: #5F6464;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: 16px;
}

.goal .r-mess:before {
  content: ' ';
  width: 1px;
  position: absolute;
  left: -60px;
  top: 0;
  display: block;
  bottom: 0;
  background: #D8D9DC;
}

.goal .text-bg {
  width: 824px;
  position: absolute;
  left: 82px;
  bottom: 0;
}
</style>

<template>
  <div>
    <v-container>
      <h1 style="text-align: left;margin-bottom: 20px">{{ $t('qualitySystem') }}</h1>

    </v-container>
  </div>
</template>

<script>
import MyMixin from "@/mixins/mixin"

export default {
  name: "QualitySystem",
  mixins: [MyMixin],
  data() {
    return {
      newsList: []
    }
  },
  methods: {
    cutText(d, length) {
      if (!d) return "";
      return d.substr(0, length);
    },

  },
  created() {
  }
}
</script>


<style scoped>
.ten_news_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px 0px;
  padding: 10px 0;
  border-bottom: 1px solid #f4f4f4;
}
</style>